import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pesoFormatter'
})
export class PesoFormatterPipe implements PipeTransform {

  transform(value: number): string {
    let valorFormatado: string;
    let unit: string;

    // Toneladas
    if (value >= 1_000) {
      valorFormatado = (value).toFixed(4);
      unit = 'ton';
    } 
    // Quilogramas
    else if (value >= 1) {
      valorFormatado = value.toFixed(4);
      unit = 'kg';
    } 
    // Gramas
    else {
      // Multiplica por 1000 para converter o valor em kg para gramas
      valorFormatado = value.toFixed(4)
      unit = 'g';
    }

    // Formata o número com separadores de milhar e duas casas decimais
    const valorFormatadoComPeso = new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(parseFloat(valorFormatado));

    return `${valorFormatadoComPeso} ${unit}`;
  }

}
